import React from 'react'

class Contact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 1,
            name: "",
            email: "",
            showForm: true,
        }
    }

    componentDidMount() {
        document.addEventListener("keypress", this.handleKeyPress, false);
        document.addEventListener("keydown", this.handleKeyPress, false);
    }

    componentWillUnmount(){
        console.log('ajajjakjka');
        
        document.removeEventListener("keypress", this.handleKeyPress, false);
        document.removeEventListener("keydown", this.handleKeyPress, false);
    }

    onClose = () => {
        this.props.onClose();
    }

    onNext = () => {
        if(this.state.step == 1){
            //validate name
            this.setState({'step': this.state.step+1});
        }
        if(this.state.step == 2){
            // validate email
            // send
            this.setState({'step': this.state.step+1});
        }
    }

    handleKeyPress = (event) => {
        const keycodes = {
            'shift': 16,
            'control': 17,
            'alt': 18,
            'backspace': 8,
            'escape': 27,
        };
        var keycode = (event.keyCode ? event.keyCode : event.which);
        console.log(event);
        console.log(keycode);
        if (event.key === 'Enter') {
            this.onNext();
            return;
        }

        if(keycode == keycodes.escape){
            this.onClose();
            return;
        }

        if (keycode == keycodes.backspace) {
            if(this.state.step == 1){
                this.setState({'name': this.state.name.slice(0, -1)});
            }else{
                this.setState({'email': this.state.email.slice(0, -1)});
            }
            return;
        }

        if (event.charCode) {
            if(this.state.step == 1){
                this.setState({'name': this.state.name + event.key});
            }else{
                this.setState({'email': this.state.email + event.key});
            }
        }
    }

    renderStep1() {
        const {name} = this.state;

        return <div>{name.length == 0 && <p className="placeholder">Type your name</p>}
            <p className="input-container">
                <span className="input">{name}</span>
                <span className="cursor"></span>
            </p>
        </div>
    }

    renderStep2() {
        const {email} = this.state;

        return <div>
            {email.length == 0 && <p className="placeholder">Type your email</p>}
            <p className="input-container">
                <span className="input">{email}</span>
                <span className="cursor"></span>
            </p>
        </div>
    }

    render() {
        const {step, name} = this.state;
        const {showForm} = this.props;

        if (!showForm) {
            return null;
        }

        return showForm && <div className="contact" onKeyDown={this.handleKeyPress}>

            <div className="left">
                {step == 1 ? this.renderStep1() : this.renderStep2()}
            </div>
            <div className="right">
                <a className="contact__close" onClick={this.onClose}></a>
                <a className="contact__next" onClick={this.onNext}>→</a>
            </div>

        </div>;
    }
}

export default Contact
