import React from 'react';
import Layout from '../components/layout'
import SEO from '../components/seo'
import Project from "../components/project";
import { navigate } from 'gatsby';

import ammehula from "../images/Ammehula.png";




// import PageTransition from 'gatsby-plugin-page-transitions';
import PropTypes from "prop-types";

class ProjectPage extends React.Component
{
    _handleKeyDown = (event) => {
        const ESCAPE_KEY = 27;


        switch( event.keyCode ) {
            case ESCAPE_KEY:
                if(this.props.location.state && this.props.location.state.fromHome){
                    window.history.back();
                    return;
                }
                navigate('/');
                return;
            default:
                break;
        }
    }

    goBack(){
        if(this.props.location.state && this.props.location.state.fromHome){
            window.history.back();
            return;
        }
        navigate('/');
        return;
    }

    componentDidMount(){
        document.addEventListener("keydown", this._handleKeyDown);

        // if (typeof window !== `undefined`) {
        //     window.settings.dust_fillStyle = this.props.pageContext.project.colors;
        //     window.settings.strokeStyle =  this.props.pageContext.project.colors;
        // }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this._handleKeyDown);
    }

    render() {
        const { project } = this.props.pageContext;
        

        return <Layout>
                <SEO title={`Project | ${project.title}`}/>
                <div id="wrapper">
                <div id="icons"></div>
                <div id="overlay_container" >
                    <div className="backButton" onClick={this.goBack.bind(this)}>
                        <div className="arrow"/>
                        <img src={ammehula} alt="No hullahooping allowed"/>
                    </div>
                    <Project project={project}/>
                </div>
                </div>
            </Layout>
    }
}

ProjectPage.propTypes = {
    project: PropTypes.object,
}


export default  ProjectPage;
