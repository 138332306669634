import PropTypes from 'prop-types'
import React from 'react'
import Projects from "./projects";
import ContactBAK from "./contact";

const Project = ({project}) => (
    <div>
        <div onKeyPress={ ()=>{ if (typeof window !== `undefined`) { window.history.back() } } } id="selected_project" className="project selected"
             ng-keypress="keypress($event)" style={{width: "100%"}}>

            <div className="header">
                <img src={`/assets/projects/${project.id}/header.jpg`}/>
            </div>

            <div className="content">
                <h1>{project.title}</h1>
                <h4 className="clients">{project.clients}</h4>
                <p className="tags">{project.tags}</p>
                <div dangerouslySetInnerHTML={{__html: project.content}}></div>
                <div className="links">
                    {project.links.map(function (link, index) {
                        return <a href={link.url} target="_blank" key={index}>
                            <div className="bolleke">
                                <div className="bolleke-circle">
                                    <i className={link.fa_icon}></i>
                                </div>
                                <h3>{project.title}</h3>
                            </div>
                        </a>;
                    })}
                </div>
            </div>
        </div>
        <Projects excludedIds={[project.id]}/>
    </div>
)

Project.propTypes = {
    project: PropTypes.object,
}

Project.defaultProps = {
    project: {},
}

export default Project

